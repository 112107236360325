<script setup></script>

<template>
    <footer class="bg-white dark:bg-gray-900 m-0 ">
        <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
            <div class="sm:flex sm:items-center sm:justify-between">
                <a href="https://arthurfranca.com/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                    <img src="../assets/logo.svg" class="w-14 " alt="AF Logo" />
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white" style="font-family: 'Abhaya Libre', serif;">Arthur&lt;<span class="text-red-600">/</span>&gt;França</span>
                </a>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://arthurfranca.com/" class="hover:underline">Arthur França™</a>. All Rights Reserved.</span>
            <span class="block text-sm mt-2 text-gray-500 sm:text-center dark:text-gray-400">contact@arthurfranca.com</span>
        </div>
    </footer>
</template>